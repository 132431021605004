export const specialtiesData = [
{
    name: 'APARATE RADIOELECTRICE DE UZ CASNIC',
    key: 'APARATE RADIOELECTRICE DE UZ CASNIC',
}, 
{
    name: 'CALCULATOARE',
    key: 'CALCULATOARE',
}, 
{
    name: 'ELECTRONICĂ',
    key: 'ELECTRONICĂ',
}, 
{
    name: 'MAŞINI, INSTALAŢII FRIGORIFICE ŞI SISTEME DE CLIMATIZARE',
    key: 'MAŞINI, INSTALAŢII FRIGORIFICE ŞI SISTEME DE CLIMATIZARE',
},
{
    name: 'TELERADIO COMUNICAŢII',
    key: 'TELERADIO COMUNICAŢII',
},
{
    name: 'ELECTROENERGETICĂ si ELECTROMECANICĂ',
    key: 'ELECTROENERGETICĂ si ELECTROMECANICĂ',
},
{
    name: 'METROLOGIA ȘI CERTIFICAREA CONFORMITĂȚII',
    key: 'METROLOGIA ȘI CERTIFICAREA CONFORMITĂȚII',
},
{
    name: 'TEHNOLOGII SI RETELE DE TELECOMUNICATII',
    key: 'TELECOMUNICAȚII',
},
{
    name: 'REȚELE DE CALCULATOARE',
    key: 'REȚELE DE CALCULATOARE',
},
{
    name: 'AUTOMATIZAREA PROCESELOR TEHNOLOGICE',
    key: 'AUTOMATICA',
},
{
    name: 'COMUNICATII POSTALE',
    key: 'COMUNICATII POSTALE',
}
]
